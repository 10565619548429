import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.div`
  width: 100%;
  padding: 0px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 300px;
  
  @media (max-width: 768px) {
    margin-top: 450px;
  }
`;

const Content = styled.div`
  max-width: min(800px, 90%);
`;

const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: clamp(48px, 10vw, 96px);
  font-weight: 900;
  font-style: italic;
  color: #141123;
  line-height: 1.1;
  margin: 0 0 24px 0;
`;

const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: clamp(18px, 4vw, 24px);
  color: #141123;
  margin: 0;
  line-height: 1.4;
`;

export const ManagerSection = () => {
  return (
    <SectionWrapper>
      <Content>
        <Title>LET'S GO!</Title>
        <Subtitle>Et moderne manager spil – uden abonnementsfordele!</Subtitle>
      </Content>
    </SectionWrapper>
  );
}; 