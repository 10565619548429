import { useState } from 'react';

export const useImageError = () => {
  const [imageErrors, setImageErrors] = useState({});
  
  const handleImageError = (imageName) => {
    setImageErrors(prev => ({ ...prev, [imageName]: true }));
    console.error(`Failed to load image: ${imageName}`);
  };

  return { imageErrors, handleImageError };
}; 