import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const HeaderOuterWrapper = styled.header`
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  position: relative;
`;

const HeaderInnerWrapper = styled.div`
  max-width: min(980px, calc(100% - 220px));
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  
  @media (max-width: 768px) {
    max-width: calc(100% - 40px);
    padding: 1rem 0;
  }
`;

const BurgerMenu = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  
  @media (max-width: 768px) {
    display: flex;
  }

  div {
    width: 1.5rem;
    height: 0.125rem;
    background: #333;
    border-radius: 10px;
    transition: all 0.3s ease;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: ${props => props.$isOpen ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${props => props.$isOpen ? '0' : '1'};
      transform: ${props => props.$isOpen ? 'translateX(20px)' : 'translateX(0)'};
    }

    &:nth-child(3) {
      transform: ${props => props.$isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 2rem;
  align-items: center;
  
  @media (max-width: 768px) {
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: -20px;
    right: -20px;
    background: white;
    padding: 0;
    display: ${props => props.$isOpen ? 'flex' : 'none'};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    gap: 0;
    width: calc(100% + 40px);
  }
`;

const NavLink = styled.a`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  letter-spacing: -0.02em;
  text-align: right;
  text-decoration: none;
  color: #333;
  transition: color 0.2s;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #eee;
    text-align: center;
    background: white;
  }
`;

const CTAButton = styled.button`
  background: linear-gradient(90deg, #6F3CFF 0%, #AD00D9 100%);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: transparent;
    border-radius: 27px;
    border: 5px solid rgba(255, 255, 255, 0.4);
    pointer-events: none;
  }
  
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    margin: 0.5rem 1rem;
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    background: linear-gradient(90deg, #6F3CFF 0%, #AD00D9 100%);
    text-align: center;
    
    &::before {
      border-radius: 27px;
    }
  }
`;

const Logo = styled.img`
  height: 40px;
  
  @media (max-width: 768px) {
    height: 32px;
  }
`;

export const Header = ({ onImageError, imageErrors }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Prevent body scroll when menu is open
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'unset';
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <>
      <HeaderOuterWrapper ref={headerRef}>
        <HeaderInnerWrapper>
          <Logo 
            src="/images/logo.png" 
            alt="Pick Six Logo" 
            onError={() => onImageError('logo')}
            style={{ display: imageErrors?.logo ? 'none' : 'block' }}
          />
          
          <BurgerMenu 
            $isOpen={isMenuOpen} 
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <div />
            <div />
            <div />
          </BurgerMenu>
          
          <NavLinks $isOpen={isMenuOpen}>
            <NavLink href="#spillet" onClick={closeMenu}>Spillet</NavLink>
            <NavLink href="#partnere" onClick={closeMenu}>Partnere</NavLink>
            <NavLink href="#faq" onClick={closeMenu}>FAQ</NavLink>
            <CTAButton onClick={closeMenu}>FÅ TIDLIG ADGANG</CTAButton>
          </NavLinks>
        </HeaderInnerWrapper>
      </HeaderOuterWrapper>
    </>
  );
}; 