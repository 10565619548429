import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background: #f5f5f5;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FooterSection = styled.div`
  h3 {
    color: #333;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  li {
    margin-bottom: 0.5rem;
  }
  
  a {
    color: #666;
    text-decoration: none;
    &:hover {
      color: #8A2BE2;
    }
  }
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <h3>Om Pick Six</h3>
          <ul>
            <li><a href="#about">Om Os</a></li>
            <li><a href="#contact">Kontakt</a></li>
          </ul>
        </FooterSection>
        
        <FooterSection>
          <h3>Support</h3>
          <ul>
            <li><a href="#faq">FAQ</a></li>
            <li><a href="#help">Hjælp</a></li>
          </ul>
        </FooterSection>
        
        <FooterSection>
          <h3>Følg Os</h3>
          <ul>
            <li><a href="https://www.facebook.com/gopicksix">Facebook</a></li>
            <li><a href="https://x.com/gopicksix">X</a></li>
            <li><a href="https://www.instagram.com/gopicksix">Instagram</a></li>
          </ul>
        </FooterSection>
      </FooterContent>
    </FooterWrapper>
  );
}; 