import { useEffect, useState } from 'react';

export const useCookieConsent = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(true);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted) setShowCookieConsent(false);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setShowCookieConsent(false);
  };

  return { showCookieConsent, handleAcceptCookies };
}; 