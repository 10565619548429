import React, { useState } from 'react';
import styled from 'styled-components';

const EmailForm = styled.form`
  display: flex;
  justify-content: center;
  gap: 0;
  margin: 2rem auto 0;
  max-width: min(400px, 90%);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 26px;
  padding: 14px;
  position: relative;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4px;
    padding: 8px;
  }
`;

const EmailInput = styled.input`
  flex: 1;
  min-height: 48px;
  padding: 0 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.35);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  color: white;
  font-size: 16px;
  margin-right: 6px;
  width: 100%;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    min-height: 48px;
    line-height: 48px;
    font-size: 18px;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const SubmitButton = styled.button`
  min-width: 48px;
  min-height: 48px;
  border: none;
  border-radius: 16px;
  background: #7FFF00;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 20px;
    height: 20px;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    height: 40px;
  }
`;

const StatusMessage = styled.div`
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  text-align: center;
  color: ${props => props.$type === 'error' ? '#ff4444' : '#7FFF00'};
  font-size: clamp(12px, 3.5vw, 14px);
  padding: 0 10px;
`;

export const EmailSignup = ({ onImageError, imageErrors }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !email.includes('@')) {
      setStatus({ type: 'error', message: 'Indtast venligst en gyldig email' });
      return;
    }

    try {
      const response = await fetch('/api/newsletter-signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      if (!response.ok) throw new Error();
      setStatus({ type: 'success', message: 'Tak for din tilmelding!' });
      setEmail('');
    } catch (error) {
      setStatus({ type: 'error', message: 'Der skete en fejl. Prøv igen senere.' });
    }
  };

  return (
    <EmailForm onSubmit={handleSubmit}>
      <EmailInput 
        type="email" 
        placeholder="Skriv din email" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="off"
        data-lpignore="true"
      />
      <SubmitButton type="submit">
        <img 
          src="/images/checkmark.png" 
          alt="Submit" 
          onError={() => onImageError('checkmark')}
          style={{ display: imageErrors?.checkmark ? 'none' : 'block' }}
        />
      </SubmitButton>
      {status.message && (
        <StatusMessage $type={status.type}>{status.message}</StatusMessage>
      )}
    </EmailForm>
  );
}; 