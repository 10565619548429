import { signOut } from '@aws-amplify/auth';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Auth } from './components/Auth/Auth';
import ComingSoon from './components/ComingSoon';
import MainPage from './components/MainPage';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';

const SignOutRoute = () => {
  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
    window.location.href = '/auth'; // Force a full page reload
  };

  // Execute sign out when component mounts
  React.useEffect(() => {
    handleSignOut();
  }, []);

  return null; // This component doesn't render anything
};

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Public routes */}
          <Route path="/auth" element={<Auth />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/signout" element={<SignOutRoute />} />
          
          {/* Protected routes */}
          <Route path="/preview" element={
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          } />
          
          {/* Default route - redirect to coming-soon */}
          <Route path="/" element={<ComingSoon />} />
          
          {/* Catch all other routes - redirect to coming-soon */}
          <Route path="*" element={<ComingSoon />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
