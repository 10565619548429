import React from 'react';
import styled from 'styled-components';

const CookieWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const CookieText = styled.p`
  margin: 0;
  padding-right: 2rem;
`;

const AcceptButton = styled.button`
  background: #7FFF00;
  color: black;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
`;

export const CookieConsent = ({ onAccept }) => {
  return (
    <CookieWrapper>
      <CookieText>
        Vi bruger cookies for at forbedre din oplevelse. Ved at fortsætte accepterer du vores brug af cookies.
      </CookieText>
      <AcceptButton onClick={onAccept}>Acceptér</AcceptButton>
    </CookieWrapper>
  );
}; 