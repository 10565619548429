import React from 'react';
import styled from 'styled-components';
import { useCookieConsent } from '../hooks/useCookieConsent';
import { useImageError } from '../hooks/useImageError';
import { CookieConsent } from './CookieConsent/CookieConsent';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { Hero } from './Hero/Hero';
import { ManagerSection } from './ManagerSection/ManagerSection';
import { VideoSection } from './VideoSection/VideoSection';

const PageWrapper = styled.div`
  min-height: 100vh;
  background: white;
  overflow-x: hidden;
  width: 100%;
  position: relative;
`;

function MainPage() {
  const { showCookieConsent, handleAcceptCookies } = useCookieConsent();
  const { imageErrors, handleImageError } = useImageError();

  return (
    <PageWrapper>
      <Header onImageError={handleImageError} imageErrors={imageErrors} />
      <Hero onImageError={handleImageError} imageErrors={imageErrors} />
      <ManagerSection />
      <VideoSection />
      <Footer />
      {showCookieConsent && (
        <CookieConsent onAccept={handleAcceptCookies} />
      )}
    </PageWrapper>
  );
}

export default MainPage;