import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`;

const Logo = styled.img`
  width: 200px;
  margin-bottom: 2rem;
`;

const Message = styled.h1`
  font-family: 'Inter', sans-serif;
  color: #333;
  text-align: center;
`;

const ComingSoon = () => {
  return (
    <Container>
      <Logo src="/images/logo.png" alt="Pick Six Logo" />
      <Message>Lidt nyt er på vej...</Message>
    </Container>
  );
};

export default ComingSoon; 