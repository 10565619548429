import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 200px 0 0 0;
  background: linear-gradient(90deg, #6F3CFF 0%, #AD00D9 100%);
`;

const BackgroundFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #6F3CFF 0%, #AD00D9 100%);
  filter: brightness(0.4);
  z-index: 1;
`;

const TeaserVideo = styled.video`
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
`;

export const VideoSection = () => {
  return (
    <VideoWrapper>
      <BackgroundFilter />
      <TeaserVideo controls>
        <source src="/teaser.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </TeaserVideo>
    </VideoWrapper>
  );
}; 