import React from 'react';
import styled from 'styled-components';
import { EmailSignup } from './EmailSignup';

const ContentSection = styled.section`
  ${props => !props.imageErrors?.background && `
    background-image: url('/images/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
  margin: 0 auto;
  width: min(1200px, calc(100% - 40px));
  height: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: clamp(20px, 5vw, 50px) 0;
  border-radius: 20px;
  overflow: visible;
  
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    margin: 0 10px;
    border-radius: 10px;
    height: 500px;
  }
`;

const Content = styled.div`
  max-width: min(800px, 90%);
  text-align: center;
  position: relative;
  z-index: 5;
  
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const Title = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: clamp(32px, 8vw, 72px);
  font-style: italic;
  font-weight: 900;
  color: white;
  text-shadow: 0 0 1px white;
  line-height: 1.1;
  margin: 0;
`;

const HighlightText = styled.span`
  color: #7FFF00;
  display: block;
  text-shadow: 0 0 1px #7FFF00;
`;

const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: clamp(16px, 4vw, 24px);
  color: white;
  margin: 1rem 0;
  line-height: 1.4;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 55%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 768px) {
    top: 70%;
  }
`;

const PreviewImage = styled.img`
  width: min(760px, 95%);
  position: relative;
  z-index: 3;
  pointer-events: none;
`;

export const Hero = ({ imageErrors, onImageError }) => {
  return (
    <ContentSection imageErrors={imageErrors}>
      <Content>
        <Title>
          KALDER ALLE
          <HighlightText>SUPERLIGA</HighlightText>
          ELSKERE!
        </Title>
        <Subtitle>
          Et moderne manager spil – uden abonnementsfordele!
        </Subtitle>
        <EmailSignup onImageError={onImageError} imageErrors={imageErrors} />
      </Content>
      <ImageContainer>
        <PreviewImage 
          src="/images/preview2.png" 
          alt="Preview of the Pick Six app interface"
          loading="lazy"
          onError={() => onImageError('preview2')}
          style={{ display: imageErrors?.preview2 ? 'none' : 'block' }}
        />
      </ImageContainer>
    </ContentSection>
  );
}; 