import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      region: 'eu-north-1',
      userPoolId: 'eu-north-1_7V8iichgR',
      userPoolClientId: '2r2ssq669gp9v4hb7jdk6ge9np',
      signUpVerificationMethod: 'code',
    }
  }
}); 