import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const currentUser = await getCurrentUser();
      const session = await fetchAuthSession();
      
      setUser(currentUser);
      setTokens({
        accessToken: session.tokens.accessToken,
        idToken: session.tokens.idToken,
        refreshToken: session.tokens.refreshToken
      });
    } catch (error) {
      setUser(null);
      setTokens(null);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    tokens,
    loading,
    refreshAuth: checkAuth
  };

  if (loading) {
    return <div>Loading...</div>; // Or your loading component
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 